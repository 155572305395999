export class LoadUserByIdAction {
  static type = '[UserAccounts] LoadUserById';
  constructor(
    public userId: string,
    public refresh = false
  ) {}
}

export class LoadUsersByIdsAction {
  static type = '[UserAccounts] LoadUsersByIds';
  constructor(
    public userIds: string[],
    public refresh = false
  ) {}
}

export class UpdateAccountPasswordAction {
  static type = '[UserAccounts] UpdateAccountPassword';
  constructor(
    public userId: string,
    public password: string
  ) {}
}
